<template>
  <div>
    <v-container
      fluid
      class="down-top-padding"
    >
      <!-- <template v-if="clubUpdated == true">
                <v-alert
                    dismissible
                    type="success"
                >Club Details have been updated</v-alert>
            </template>
            <template v-if="clubUpdated == false">
                <v-alert
                dismissible
                type="danger"
                >Club Details failed to update</v-alert>
            </template> -->
      <v-row>
        <v-col
          sm="12"
          lg="12"
          md="12"
        >
          <v-card class="mb-5">
            <v-card-text class="pa-5">
              <v-toolbar flat>
                <v-toolbar-title>Package Details</v-toolbar-title>
                <v-spacer />
                <!-- <v-btn
                                    @click="showStripeField = true"
                                    text
                                >
                                    Change Stripe ID
                                </v-btn> -->
                <!-- <template v-if="showStripeField">
                                    <v-text-field
                                        v-model="stripeIdChange"
                                        label="Enter new ID"
                                    >
                                    </v-text-field>
                                    <v-btn
                                        @click="changeStripeId"
                                        text
                                    >
                                        Submit
                                    </v-btn>
                                </template> -->
              </v-toolbar>
              <v-divider />
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <h3
                        class="darken-2"
                        style="font-size: 1rem; color: grey"
                      >
                        Record ID
                      </h3>
                      <br>
                      <p>{{ selectedPackage._id || "Not Provided" }}</p>
                      <v-divider />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <h3
                        class="darken-2"
                        style="font-size: 1rem; color: grey"
                      >
                        Package Name
                      </h3>
                      <br>
                      <p>{{ selectedPackage.name || "Not Provided" }}</p>
                      <v-divider />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <h3
                        class="darken-2"
                        style="font-size: 1rem; color: grey"
                      >
                        Description
                      </h3>
                      <br>
                      <p>{{ selectedPackage.description || "Not Provided" }}</p>
                      <v-divider />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <h3
                        class="darken-2"
                        style="font-size: 1rem; color: grey"
                      >
                        Type
                      </h3>
                      <br>
                      <p>{{ selectedPackage.type || "Not Provided" }}</p>
                      <v-divider />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <h3
                        class="darken-2"
                        style="font-size: 1rem; color: grey"
                      >
                        Assigned Club Products
                      </h3>
                      <br>
                      <p>{{ selectedPackage.club_products.map(clubProduct => clubProduct.name).join(',') || "Not Provided" }}</p>
                      <v-divider />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <h3
                        class="darken-2"
                        style="font-size: 1rem; color: grey"
                      >
                        Assigned Direct Products
                      </h3>
                      <br>
                      <p>{{ selectedPackage.direct_products.map(directProduct => directProduct.name).join(',') || "Not Provided" }}</p>
                      <v-divider />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  export default {
    name: 'Registrant',
    data () {
      return {
        page: {
          title: 'Club Details',
          icon: 'user',
        },
        breadcrumbs: [
          {
            text: 'Portal',
            to: '/admin',
          },
          {
            text: 'Clubs',
            to: '/admin/clubs',
          },
          {
            text: 'Club',
            disabled: true,
          },
        ],
      }
    },
    created () {
      this.viewPackage(this.$route.params.id)
    },
    computed: {
      ...mapGetters(['selectedPackage']),
    },
    methods: {
      ...mapActions(['viewPackage']),
      back () {
        this.$router.back()
      },
    },
  }
</script>
